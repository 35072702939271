<template>
  <div>
    <b-form-group
      id="fieldset-franchise-status"
      label="Status"
      label-for="franchise-toUrlParams-active"
      label-cols="3"
      style="width: 220px"
    >
      <b-form-select
        id="franchise-toUrlParams-active"
        v-model="toUrlParams.active"
        :options="[
          { value: null, text: 'Active & Inactive' },
          { value: true, text: 'Active' },
          { value: false, text: 'Inactive' },
        ]"
      ></b-form-select>
    </b-form-group>
    <data-table
      class="mt-2"
      :uri="uri"
      :uri-params="uriParams"
      :fields="fields"
      :filter-label="filterLabel"
      no-filter
    >
      <template #headerFilters>
        <b-row no-gutters>
          <b-col cols="12" lg="4">
            <BaseFormFranchise
              :active="toUrlParams.active"
              @selected-code="toUrlParams.franchiseCode = $event"
              no-label
            />
          </b-col>
          <b-col cols="12" lg="3">
            <base-form-with-clear
              @clear="
                toUrlParams.country = null
                toUrlParams.state = null
              "
            >
              <BaseFormSelectCountry
                v-model="toUrlParams.country"
                @input="toUrlParams.state = null"
                use-label
              />
            </base-form-with-clear>
          </b-col>
          <b-col cols="12" lg="3">
            <base-form-with-clear @clear="toUrlParams.state = null">
              <BaseFormSelectState
                :country="toUrlParams.country"
                v-model="toUrlParams.state"
                :disabled="!toUrlParams.country"
              />
            </base-form-with-clear>
          </b-col>
          <b-col cols="12" lg="2">
            <b-form-input
              type="search"
              maxlength="10"
              v-model="toUrlParams['territories.zipCode']"
              placeholder="Territory"
              debounce="500"
            ></b-form-input>
          </b-col>
        </b-row>
      </template>

      <template #cell(action)="data">
        <b-dropdown size="sm" id="franchise-action" text="Action" class="d-print-none">
          <b-dropdown-item @click="onOpen(data.item.franchiseCode)"
            ><b-icon icon="folder2-open" aria-hidden="true"></b-icon>
            Open</b-dropdown-item
          >
          <b-dropdown-item
            v-if="$can('update', 'Franchise')"
            @click="
              $bvModal.msgBoxOk('Will open #' + data.item.franchiseCode + ' for editing')
            "
            ><b-icon icon="pencil" aria-hidden="true"></b-icon> Edit</b-dropdown-item
          >
        </b-dropdown>
      </template>

      <template #cell(franchiseCode)="data">
        <a
          href="javascript:;"
          v-b-tooltip.hover
          title="View Franchise"
          @click="ModalFranchiseShow(data.value)"
          >{{ data.value }}</a
        >
      </template>

      <!-- PURCHASED -->
      <template #cell(purchased)="data">
        {{
          $moment(data.value).isValid() ? $moment(data.value).format('MM/DD/YYYY') : '-'
        }}
      </template>

      <template #cell(active)="data">
        {{ !data.value ? 'No' : 'Yes' }}
      </template>

      <template #cell(director)="data">
        <template v-for="(v, i) in data.value">
          <a
            href="javascript:;"
            v-b-tooltip.hover
            title="View User"
            @click="$store.commit('SET_USER_PROFILE_ID', v.id)"
            :key="'user-link-' + i"
            >{{ `${v.profile.fname} ${v.profile.lname}` }}</a
          >
          <br v-if="data.value[i + 1]" :key="'user-link-d-' + i" />
        </template>
      </template>

      <template #cell(renewDate)="data">
        {{ $d(data.value).isValid() ? `${$d(data.value).format('YYYY-MM-DD')}` : null }}
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/DataTable'
import { constants } from '@/shared/constants'

export default {
  components: { DataTable },
  name: 'FranchiseList',
  props: {
    data: Array,
  },
  data() {
    return {
      uri: constants.API_URI_FRANCHISES,
      toUrlParams: {
        franchiseCode: null,
        country: null,
        state: null,
        active: true,
        'territories.zipCode': null,
      },
      filterLabel: 'Territory',
      viewFranchiseCode: null,
    }
  },
  computed: {
    ...mapGetters(['franchise', 'franchises']),
    uriParams() {
      const uriParams = {}
      for (const k in this.toUrlParams) {
        if (![null, ''].includes(this.toUrlParams[k])) uriParams[k] = this.toUrlParams[k]
      }
      return uriParams
    },
    fields() {
      const fields = [
        { key: 'franchiseCode', label: 'Code', sortable: true },
        { key: 'purchased', label: 'Purchased', sortable: true },
        {
          key: 'director',
          label: 'Owners',
          sortable: false,
          exportvalue: {
            field: 'director',
            callback: (value) => {
              return value
                .map((e) => {
                  return `${e.profile.fname} ${e.profile.lname}`
                })
                .join(', ')
            },
          },
        },
        { key: 'city', label: 'City', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        // { key: 'renewDate', label: 'Renew Date', sortable: true, exportvalue: {
        //   field: 'renewDate',
        //   callback: (value) => {
        //     return this.$moment(value).isValid() ? this.$moment(value).format('YYYY-MM-DD') : null
        //   }
        // }}
      ]

      if (this.toUrlParams.active === null) {
        fields.push({ key: 'active', sortable: true })
      }
      if (this.$can('update', 'Members')) {
        fields.unshift({ key: 'action', label: '', sortable: false, print: false })
      }

      return fields
    },
    franchiseTableData() {
      const franchiseList = this.franchises.map((f) => ({
        franchise_code: f.franchiseCode,
        active: f.active,
        Owners: f.director[0]
          ? `${f.director[0].profile.fname} ${f.director[0].profile.lname}`
          : null,
        city: f.city,
        state: f.state,
        country: f.country,
        renewal_date:
          f.renewDate && !f.renewDate.startsWith('-')
            ? this.$moment(f.renewDate).format('MM/DD/YYYY')
            : null,
      }))

      return franchiseList
    },
  },
  methods: {
    ...mapActions(['getFranchise', 'getFranchises', 'resetFranchises']),
    onOpen(franchiseCode) {
      const FranchiseDetails = this.$router.resolve({
        name: 'FranchiseDetails',
        params: { franchiseCode },
      })
      window.open(FranchiseDetails.href, '_blank')
    },
  },
  mounted() {
    this.getFranchises({
      active: true,
    })
  },
  beforeDestroy() {
    this.resetFranchises()
  },
}
</script>
